import React, {useEffect, useState} from "react";
import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import ThesisApi from "../../api/search/ThesisApi";
import IFaculty from "../../dto/IFaculty";

interface FilterSelectorProps {
    label: string,
    onChange: any
}

const FacultyFilterSelector: React.FC<FilterSelectorProps> = ({label, ...props}) => {

    const [faculty, setFaculty] = useState<IFaculty[]>([])

    useEffect(() => {
        ThesisApi.fetchFaculty()
            .then(setFaculty)
            .catch(console.log)
    }, [])

    const renderItems = () => faculty
        .map(({name}) => (
            <MenuItem key={name} value={name}>
                {name}
            </MenuItem>
        ))

    return (
        <FormControl fullWidth size={"small"}>
            <InputLabel id="faculty-select">{label}</InputLabel>
            <Select style={{backgroundColor: "white"}} label={label} defaultValue={'0'} onChange={props.onChange}>
                <MenuItem value={0} id={"none"}>
                    <em> --- </em>
                </MenuItem>
                {renderItems()}
            </Select>
        </FormControl>
    )
}

export default FacultyFilterSelector;
