import {graduationWorkPortalUrl, ThesisApiConfig} from "../config";
import ThesisSearchFilterParams from "../../model/ThesisSearchFilterParams";
import IThesis from "../../dto/search/IThesis";
import ISpeciality from "../../dto/search/ISpeciality";
import IFaculty from "../../dto/IFaculty";
import FileSaver from "file-saver";
import IPerson from "../../dto/IPerson";

const {get, post, put} = ThesisApiConfig;

export default {
    fetchTheses: (filterParams: ThesisSearchFilterParams | undefined,): Promise<IThesis[]> => {
        return get(`search/theses`, {params: filterParams})
    },
    fetchSpecialities: (): Promise<ISpeciality[]> => {
        return get("/search/specialities")
    },
    fetchFaculty: (): Promise<IFaculty[]> => {
        return get("/search/faculty")
    },
    countTheses: (filterParams: ThesisSearchFilterParams | undefined,): Promise<number> => {
        return get(`search/theses/count`, {params: filterParams})
    },
    downloadFinalTheseArchive: (ids: Array<string>): void => {
        FileSaver.saveAs(graduationWorkPortalUrl + `/thesis/original?ids=${ids}`, "archive.zip");
    },
    fetchSupervisor: (): Promise<IPerson[]> => {
        return get(`/search/supervisors`);
    },
    fetchRecipient: (): Promise<IPerson[]> => {
        return get(`/search/reviewers`);
    }
}
