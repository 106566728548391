import i18n from "i18next";
import {initReactI18next} from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            "Select user type": "",
            "Your theses": "Your theses",
            "Your theses description": "This page lists all your thesis paper that have been already uploaded and thesis papers that still need" +
                " to be upload. If you are facing any problems in finding your theses papers, please contact your" +
                " supervisor.",
            "Thesis Title": "Thesis Title",
            "Supervisor": "Supervisor",
            "Student": "Student",
            "Status": "Status",
            "Upload Thesis": "Upload thesis",
            "Download Thesis": "Download Thesis",
            "WorkUploaded": "Uploaded",
            "Waiting for uploading": "Waiting for uploading",

            "Thesis Language": "Thesis language",
            "Latvian": "Latvian",
            "English": "English",
            "Russian": "Russian",

            "Work Parameters": "Work Parameters",
            "Pages": "Pages",
            "Tables": "Tables",
            "Figures": "Figures",
            "Appendix": "Appendix",
            "Literature": "Literature",

            "Theses Keywords": 'Thesis Keywords',
            "Action": 'Action',
            "Limit": 'Limit',

            "Abstract in latvian": "Abstract in Latvian",
            "Abstract in english": "Abstract in English",
            "Symbols Limit": "Symbols Limit",

            "Thesis": "Thesis",
            "Thesis upload label hint text": "Your thesis have to be uploaded in pdf format file. The thesis must begin with table of a content (start at page 5)",
            "Thesis upload upload hint text": "Drag the file with the text of the thesis here or browse.",
            "Max thesis size file": " File max size - 50mb",
            "Uploaded": "Uploaded",

            "Supplementary Materials": "Supplementary Materials",
            "Supplementary upload hint text": "Optionally, you can upload additional files, such as source code, presentation and etc. Please archive the files before uploading (*.zip, *.rar, *.7z)",
            "Supplementary file upload hint text": "Drag additional materials here or browse",
            "Max supplementary size file": "File max size - 50mb",

            "Confiramtion": "Confirmation",
            "Confiramtion Publication": "Consent",
            "Confirmation accept": "I have read and agreed with this statement(obligatory of agreement)",
            "Confirmation accept publication": "I have read and agreed with this statement.",
            "Final Thesis Document": "Final Thesis Document",
            "Ready to confirm": "Ready to confirm",

            "Next": "Next",
            "Back": "Back",
            "Confirm and finish": "Confirm and finish",

            "Please fill in all required fields in order to continue": "Please fill in all required fields in order to continue",
            "Select the language of your work": "Select the language of your work",
            "Fill in the table of parameters of your thesis": "Fill in the table of parameters of your thesis",
            "Pages - The number of pages of your thesis excluding the appendix": "",
            "Tables - the number of tables in the main text of the thesis": "",
            "Figures - the number of figures in the main text of the thesis": "",
            "Appendix - the number of appendix in the main text of the thesis": "",
            "Literature - the number of used literature sources in the thesis": "",

            "Details": "Details",
            "Abstracts": "Abstracts",
            "Uploading": "Uploading",
            "Confirmation": "Confirmation",
            "Check": "Check",

            "key-words advice": "Choose keywords related to your thesis paper. Add at least three words in each of required languages",
            "abstract advice": "Add the content of your annotation in all required languages",
            "uploading advice": "The uploaded PDF file of the thesis must begin with table of contents. Page numbering of your thesis should start at page 5",
            "confirm advice": "Please read these statements and tick the  boxes to confirm your choice",
            //"confirm advice": "Please read this statement and tick the box stating that you agree with the terms and conditions",
            "final advice": "The final document has been generated. Please check it before confirmation",

            "Viewer": "Viewer",
            "Role": "Role",
            "Select": "Select",
            "Select role": "Select role",
            "role select advice": "If you are going to upload a new thesis or download a copy of an already uploaded paper you should be logged in as a student",

            "Graduation works portal": "Graduation works portal",
            "sign-in advice": "Graduation works portal is a centralized location for students to post and store their graduations works",
            "sign-in": "Sign in",
            "Password": "Password",
            "Login": "Login",
            "Enter": "Sign in",



            "check-step attention" : "ATTENTION!",
            "check-step should checked" : "Download and check the generated file to ensure there are no errors and it has been correctly generated:",
            "check-step list-element 1" : "Verify that the title on the cover page is correct.",
            "check-step list-element 2" : "Ensure the correct supervisor is listed.",
            "check-step list-element 3" : "The supervisor and consultant should not be the same person. The consultant is an additional person to the supervisor, appointed together with the supervisor when the thesis topic is assigned.",
            "check-step list-element 4" : "Check the generated annotations.",
            "check-step list-element 5" : "Verify the page numbering.",
            "check-step list-element 6" : "Ensure the table of contents is on the 5th page.",
            "check-step list-element 7" : "Check the entire text of the work.",
            "check-step any errors" : "If any errors are found, upload a new version of the file. If you encounter issues with the title, supervisor, or consultant, contact the Academic Office (Room 100, student@tsi.lv, +371 67100523).",
            "confirmation check-step" : "I have checked the generated file and confirm that it does not require any corrections and is the final version of the thesis.",

                        
            "header-attention login-page": "ATTENTION!",
            "first-paragraph login-page": "The DRAFT version of the thesis for the PRELIMINARY DEFENSE should be uploaded to E-TSI into the section:",
            "redirect-text login-page": "Information for Final Year Students",
            "second-paragrapgh login-page":"The only FINAL VERSION of the thesis must be uploaded to the Graduation works portal after successfully completing the preliminary defense procedure and making the necessary additions and changes.",
            "list-header login-page":"The final version of the thesis must be uploaded:",
            "list-element-1 login-page":"10 days before the defense for master's and professional programs;",
            "list-element-2 login-page":"5 days before the defense for bachelor's programs.",
            "third-paragraph login-page":"Once the final version is uploaded to this portal, IT CANNOT BE CHANGED. Be careful with what you upload to the system.",

            
            "St number": "St number",
            "Faculty": "Faculty",
            "Speciality": "Speciality",
            "Path": "Path",
            "Author": "Author",
            "Name": "Name",
            "Reviewer": "Reviewer",
            "Year": "Year",
            "Semester": "Semester",
            "Info": "Info",

            "Selected Theses": "Selected Theses",
            "Student Number": "Student Number",
            "Faculty Name": "Faculty Name",

        }
    },
    lv: {
        translation: {
            "Select user type": "Darba Nosaukums",
            "Your theses": "Jūsu Gala pārbaudījuma  darbi",
            "Your theses description": "Šajā lapā ir uzskaitīti visi jūsu Gala pārbaudījuma  darbi, kas jau ir augšupielādēti, un tie diplomdarbu darbi, kas vēl ir jāaugšupielādē. Ja Jūs neredzat savu darbu sarakstā, lūdzam, sazinieties ar savu darba vadītāju.",
            "Thesis Title": "Darba Nosaukums",
            "Supervisor": "Darba Vadītājs",
            "Student": "Students",
            "Status": "Statuss",
            "Upload Thesis": "AUGŠUPIELĀDĒT DARBU",
            "Download Thesis": "Lejupielādēt Darbu",
            "WorkUploaded": "Darbs ir piekrauts",
            "Waiting for uploading": "Ielādes gaidas",

            "Thesis Language": "Darba Valoda",
            "Latvian": "Latviešu",
            "English": "Angļu",
            "Russian": "Krievu",

            "Work Parameters": "Darba Parametri",
            "Pages": "Lapaspuses",
            "Tables": "Tabulas",
            "Figures": "Attēli",
            "Appendix": "Pielikumi",
            "Literature": "Literatūra",

            "Theses Keywords": 'Atslēgas vārdi',
            "Action": 'Darbība',
            "Limit": 'Limits',

            "Abstract in latvian": "Anotācija latviešu valodā",
            "Abstract in english": "Anotācija angļu valodā",
            "Symbols Limit": "Simbolu skaita limits",

            "Thesis": "Diplomdarbs",
            "Thesis upload label hint text": "Darbs ir jāielādē pdf formāta failā. Darbam jāsākas ar satura rādītāju (sāciet ar 5. lpp.)",
            "Thesis upload upload hint text": "Velciet failu ar darba tekstu šeit vai pārlūkojiet. ",
            "Max thesis size file": "Faila maksimālais izmērs - 50 mb",
            "Uploaded": "Augšupielādēts",

            "Supplementary Materials": "Papildu materiāli",
            "Supplementary upload hint text": "Pēc izvēles varat augšupielādēt papildu failus, piemēram, avota kodu, prezentāciju utt. Lūdzu, arhivējiet failus pirms augšupielādes (*.zip, *.rar, *.7z)",
            "Supplementary file upload hint text": "Velciet papildu materiālus šeit vai pārlūkojiet.",
            "Max supplementary size file": "Faila maksimālais izmērs - 50 mb",

            "Confiramtion": "Galvojums",
            "Confiramtion Publication": "Piekrišana",
            "Confirmation accept": "Esmu izlasījis šo apgalvojumu un piekrītu tam(obligāta vienošanās)",
            "Confirmation accept publication": "Esmu izlasījis šo apgalvojumu un piekrītu tam",
            "Final Thesis Document": "Noslēguma darba dokuments",
            "Ready to confirm": "Gatavs apstiprināšanai",

            "Next": "Tālāk",
            "Back": "Atpakaļ",
            "Confirm and finish": "Apstiprināt un Pabeigt",

            "Please fill in all required fields in order to continue": "Lūdzu, aizpildiet visus obligātos laukus, lai turpinātu",
            "Select the language of your work": "Izvēlieties darba valodu",
            "Fill in the table of parameters of your thesis": "Aizpildiet darba parametru tabulu",
            "Pages - The number of pages of your thesis excluding the appendix": "Lapas - jūsu darba lappušu skaits, izņemot pielikumu",
            "Tables - the number of tables in the main text of the thesis": "Tabulas - tabulu skaits darba pamattekstā",
            "Figures - the number of figures in the main text of the thesis": "Attēli - attēlu skaits darba pamattekstā",
            "Appendix - the number of appendix in the main text of the thesis": "Pielikums - darba pielikuma lappušu skaits",
            "Literature - the number of used literature sources in the thesis": "Literatūra - darbā izmantoto literatūras avotu skaits",

            "Details": "Detaļas",
            "Abstracts": "Anotācijas",
            "Uploading": "Augšupielāde",
            "Confirmation": "Apstiprinājums",
            "Check": "Pārbaudie",

            "key-words advice": "Izvēlieties atslēgvārdus, kas saistīti ar jūsu promocijas darbu. Pievienojiet vismaz trīs vārdus katrā no nepieciešamajām valodām",
            "abstract advice": "Pievienojiet anotācijas saturu visās nepieciešamajās valodās",
            "uploading advice": "Darba augšupielādētajam PDF failam jāsākas ar satura rādītāju. Jūsu darba lappušu numerācijai jāsākas ar 5. lappusi",
            "confirm advice": "Lūdzu, izlasiet šo paziņojumu un atzīmējiet izvēles rūtiņu, norādot, ka piekrītat noteikumiem un nosacījumiem",
            "final advice": "Galīgais dokuments ir izveidots. Lūdzu, pārbaudiet to pirms apstiprināšanas",

            "Viewer": "Skatītājs",
            "Role": "Loma",
            "Select": "Izvēliet",
            "Select role": "Izvēlieties lietotāja veidu",
            "role select advice": "Ja jūs plānojat augšupielādēt jaunu  Gala pārbaudījuma  darbu vai lejupielādēt jau augšupielādēta darba kopiju, jums ir jāpiesakās kā studentam",

            "Graduation works portal": "Izlaiduma darbu portāls",
            "sign-in advice": "Izlaiduma darbu portāls ir centralizēta vieta, kur studenti var ievietot un uzglabāt savus izlaiduma darbus",
            "sign-in": "Pierakstīties",
            "Password": "Parole",
            "Login": "Lietotājvārds",
            "Enter": "Ienākt",



            "check-step attention" : "UZMANĪBU!",
            "check-step should checked" : "Lejupielādējiet un pārbaudiet ģenerēto failu, lai pārliecinātos, ka tajā nav kļūdu un tas ir pareizi ģenerēts:",
            "check-step list-element 1" : "Pārbaudiet, vai uz titullapas ir pareizi uzrakstīts tēmas nosaukums.",
            "check-step list-element 2" : "Pārliecinieties, ka ir norādīts pareizais vadītājs.",
            "check-step list-element 3" : "Vadītājs un konsultants nedrīkst būt viena un tā pati persona. Konsultants ir papildu persona pie vadītāja, kas tiek iecelta kopā ar vadītāju, kad tiek piešķirta izlaiduma darba tēma.",
            "check-step list-element 4" : "Pārbaudiet, kā ir ģenerētas anotācijas.",
            "check-step list-element 5" : "Pārbaudiet visu lapu numerāciju.",
            "check-step list-element 6" : "Pārliecinieties, ka saturs ir 5. lapā",
            "check-step list-element 7" : "Pārbaudiet visa darba tekstu.",
            "check-step any errors" : "Ja tiek atrastas kļūdas, augšupielādējiet jaunu faila versiju. Ja rodas problēmas ar tēmas nosaukumu, vadītāju vai konsultantu, sazinieties ar Studiju daļu (100. kab., student@tsi.lv, +371 67100523).",
            "confirmation check-step" : "Esmu pārbaudījis ģenerēto failu un apstiprinu, ka tas neprasa nekādas izmaiņas un ir gala versija manam izlaiduma darbam.",
            

            "header-attention login-page": "UZMANĪBU!",
            "first-paragraph login-page": "Versiju diplomdarbam PRIEKŠAIZSTĀVĒŠANAI jāaugšupielādē E-TSI sekcijā:",
            "redirect-text login-page": "Informācija izlaiduma kursu studentiem",
            "second-paragrapgh login-page":"Izlaiduma darbu portālā tiek augšupielādēta tikai GALĪGĀ VERSIJA izlaiduma darbam pēc veiksmīgas priekšaizstāvēšanas procedūras pabeigšanas un nepieciešamo papildinājumu un izmaiņu veikšanas.",
            "list-header login-page":"Galīgā izlaiduma darba versija jāaugšupielādē:",
            "list-element-1 login-page":"10 dienas pirms aizstāvēšanas maģistra un profesionālajām programmām;",
            "list-element-2 login-page":"5 dienas pirms aizstāvēšanas bakalaura programmām.",
            "third-paragraph login-page":"Kad galīgā versija ir augšupielādēta portālā, to vairs NEVARĒS MAINĪT. Esiet uzmanīgi ar to, ko augšupielādējat sistēmā",


            "St number": "St numurs",
            "Faculty": "Fakultāte",
            "Speciality": "Specialitāte",
            "Path": "Studiju forma",
            "Author": "Autors",
            "Name": "Nosaukums",
            "Reviewer": "Recenzents",
            "Year": "Gads",
            "Semester": "Semestris",
            "Info": "Informācija",

            "Selected Theses": "Izvēlētie darbi",
            "Student Number": "Studenta numurs",
            "Faculty Name": "Fakultātes nosaukums",

        }
    },
    ru: {
        translation: {
            "Select user type": "Название Работы",
            "Your theses": "Ваши выпускные работы",
            "Your theses description": "На этой странице перечислены все ваши выпускные работы, которые уже были загружены, и работы, которые еще нужно загрузить. Если Вы не видите название Вашей работы в этом списке, то обратитесь, пожалуйста, к своему научному руководителю.",
            "Thesis Title": "Название Работы",
            "Supervisor": "Руководитель",
            "Student": "Студент",
            "Status": "Статус",
            "Upload Thesis": "ЗАГРУЗИТЬ РАБОТУ",
            "Download Thesis": "СКАЧАТЬ РАБОТУ",
            "WorkUploaded": "Работа загружена",
            "Waiting for uploading": "Ожидание загрузки",

            "Thesis Language": "Язык Работы",
            "Latvian": "Латышский",
            "English": "Английский",
            "Russian": "Русский",

            "Work Parameters": "Параметры Работы",
            "Pages": "Страницы",
            "Tables": "Таблицы",
            "Figures": "Рисунки",
            "Appendix": "Приложения",
            "Literature": "Литература",

            "Theses Keywords": 'Ключевые слова',
            "Action": 'Действия',
            "Limit": 'Лимит',

            "Abstract in latvian": "Аннотация на латышском языке",
            "Abstract in english": "Аннотация на английском языке",
            "Symbols Limit": "Лимит символов",

            "Thesis": "Дипломная работа",
            "Thesis upload label hint text": "Работа должна быть загружена в виде файла в формате pdf. Загружаемая работа должна начинаться с оглавления (с 5 страницы)",
            "Thesis upload upload hint text": "Перетащите файл с текстом работы или просмотрите.",
            "Max thesis size file": "Максимальный размер файла – 50 mb",
            "Uploaded": "Загружено",

            "Supplementary Materials": "Дополнительные Материалы",
            "Supplementary upload hint text": "При желании вы можете загрузить дополнительные файлы, такие как исходный код, презентация и т. д. Пожалуйста, заархивируйте файлы перед загрузкой (*.zip, *.rar, *.7z)",
            "Supplementary file upload hint text": "Перетащите сюда дополнительные материалы.",
            "Max supplementary size file": "Максимальный размер файла – 50 mb",

            "Confiramtion": "Подтверждение",
            "Confirmation accept": "Я прочитал и согласен с этим утверждением",
            "Final Thesis Document": "Окончательный документ работы",
            "Ready to confirm": "Готов для подтверждения",

            "Next": "Далее",
            "Back": "Назад",
            "Confirm and finish": "Подтвердить и завершить",

            "Please fill in all required fields in order to continue": "Заполните все необходимые поля, чтобы продолжить",
            "Select the language of your work": "Выберите язык вашей работы",
            "Fill in the table of parameters of your thesis": "Заполните таблицу параметров вашей работы",
            "Pages - The number of pages of your thesis excluding the appendix": "Страницы - Количество страниц работы без учета приложения.",
            "Tables - the number of tables in the main text of the thesis": "Таблицы - количество таблиц в основном тексте работы",
            "Figures - the number of figures in the main text of the thesis": "Рисунки - количество рисунков в основном тексте диссертации",
            "Appendix - the number of appendix in the main text of the thesis": "Приложение - количество страниц приложения работы",
            "Literature - the number of used literature sources in the thesis": "Литература - количество использованных литературных источников в работе",

            "Details": "Детали",
            "Abstracts": "Аннотации",
            "Uploading": "Загрузка",
            "Confirmation": "Подтверждение",
            "Check": "Проверка",

            "key-words advice": "Выберите ключевые слова, относящиеся к вашей дипломной работе. Добавьте не менее трех слов на каждом из необходимых языков",
            "abstract advice": "Добавьте содержимое аннотации на всех необходимых языках",
            "uploading advice": "Загружаемый PDF-файл работы должен начинаться с оглавления. Нумерация страниц работы должна начинаться с 5 страницы",
            "confirm advice": "Прочтите это заявление и поставьте галочку, подтверждающую, что вы согласны с условиями",
            "final advice": "Окончательный документ готов. Пожалуйста, проверьте его перед подтверждением",

            "Viewer": "Наблюдатель",
            "Role": "Роль",
            "Select": "Выбрать",
            "Select role": "Выберите роль",
            "role select advice": "Если вы собираетесь загрузить новую выпускную работу или скачать копию уже загруженной работы, вы должны войти в систему как студент.",

            "Graduation works portal": "Портал выпускных работ",
            "sign-in advice": "Портал выпускных работ - централизованное место для размещения и хранения студентами своих выпускных работ",
            "sign-in": "Вход в систему",
            "Password": "Пароль",
            "Login": "Логин",
            "Enter": "Войти",


            "check-step attention" : "ВНИМАНИЕ!",
            "check-step should checked" : "Скачайте и проверьте полученный файл, чтобы убедиться, что в нем нет ошибок и он правильно сгенерировался:",
            "check-step list-element 1" : "Проверьте, правильно ли написано название темы на титульном листе.",
            "check-step list-element 2" : "Убедитесь, что указан правильный руководитель.",
            "check-step list-element 3" : "Руководитель и консультант не должны совпадать. Консультант это дополнительная к руководителю персона, которая назначается совместно с руководителем во время закрепления темы выпускной работы.",
            "check-step list-element 4" : "Проверьте, как сгенерировались аннотации.",
            "check-step list-element 5" : "Проверьте нумерацию всех страниц.",
            "check-step list-element 6" : "Проверьте, чтобы содержание находилось на 5-й странице",
            "check-step list-element 7" : "Проверьте текст всей работы.",
            "check-step any errors" : "В случае обнаружения ошибок загрузите новую версию файла. При возникновении проблем с названием темы, именем руководителя или консультанта обратитесь в учебный отдел (100 каб., student@tsi.lv, +371 67100523).",
            "confirmation check-step" : "Я проверил(а) сгенерировавшийся файл и подтверждаю, что он не требует исправлений и является финальной версией выпускной работы.",
            

            "header-attention login-page": "ВНИМАНИЕ!",
            "first-paragraph login-page": "Версию выпускной работы для ПРЕДВАРИТЕЛЬНОЙ ЗАЩИТЫ следует загрузить в E-TSI в раздел:",
            "redirect-text login-page": "Информация для студентов выпускных курсов",
            "second-paragrapgh login-page":"В Портал выпускных работ загружается только ФИНАЛЬНАЯ ВЕРСИЯ выпускной работы после успешного прохождения процедуры предварительной защиты и внесения необходимых дополнений и изменений.",
            "list-header login-page":"Финальная версия выпускной работы должна быть загружена:",
            "list-element-1 login-page":"за 10 дней до защиты для магистерских и профессиональных программ;",
            "list-element-2 login-page":"за 5 дней до защиты для бакалаврских программ.",
            "third-paragraph login-page":"После загрузки финальной версии работы в этот портал изменить её будет НЕВОЗМОЖНО. Будьте внимательны с тем, что Вы загружаете в систему.",

            
            "St number": "Студ. номер",
            "Faculty": "Факультет",
            "Speciality": "Специальность",
            "Path": "Поток",
            "Author": "Автор",
            "Name": "Название",
            "Reviewer": "Рецензент",
            "Year": "Год",
            "Semester": "Семестр",
            "Info": "Информация",

            "Selected Theses": "Выбранные работы",

            "Student Number": "Студенческий номер",
            "Faculty Name": "Факультет",

        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
