import React, {useEffect, useState} from "react";
import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import ThesisApi from "../../api/search/ThesisApi";
import ISpeciality from "../../dto/search/ISpeciality";

interface FilterSelectorProps {
    label: string,
    onChange: any
}

const SpecialityFilterSelector: React.FC<FilterSelectorProps> = ({label, ...props}) => {

    const [specialities, setSpecialities] = useState<ISpeciality[]>([])

    useEffect(() => {
        ThesisApi.fetchSpecialities()
            .then(setSpecialities)
            .catch(e => console.log(e))
    }, [])

    const renderItems = () => specialities
        .map(({name, id, code}, index) => (
            <MenuItem key={code} id={code} value={id}>
                {"(" + code + ") " + name}
            </MenuItem>
        ))

    return (
        <FormControl fullWidth size={"small"}>
            <InputLabel id="speciality-select">{label}</InputLabel>
            <Select style={{backgroundColor: "white"}} label={label} defaultValue={'0'} onChange={props.onChange}>
                <MenuItem value={0} key={1}>
                    <em> --- </em>
                </MenuItem>
                {renderItems()}
            </Select>
        </FormControl>
    )
}

export default SpecialityFilterSelector;
