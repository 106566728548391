import React from "react";
import IThesis from "../../dto/search/IThesis";
import {makeStyles} from "@material-ui/core/styles";
import {Checkbox, IconButton, TableCell, TableRow, Tooltip, Typography} from "@mui/material";
import DepartmentEnum from "../../model/DepartmentEnum";
import InfoIcon from '@mui/icons-material/Info';
import i18n from "i18next";
import {useTranslation} from "react-i18next";
import DepartmentEnumLv from "../../model/DepartmentEnumLv";

type ThesesRowProps = {
    thesis: IThesis
    onInfoClick: (id: number) => void,
    onCheckBoxClick: (t: IThesis) => void,
    isSelected?: boolean,
}


const ThesesRow: React.FC<ThesesRowProps> = ({thesis, isSelected = false, ...props}) => {

    const classes = useStyles();
    const {t, i18n} = useTranslation();

    return (
        <TableRow
            style={{position: 'relative', zIndex: 1}}
            className={classes.row}
            key={thesis.id} hover
        >
            <TableCell key={"check_box"}>
                <div style={{display: "flex"}}>
                    <Checkbox
                        onClick={() => props.onCheckBoxClick(thesis)}
                        checked={isSelected}
                        key={thesis.id}
                        size={"medium"}
                    />
                </div>
            </TableCell>
            <TableCell width={100} key={"st_number"} align={"center"}>
                {thesis.stNumber}
            </TableCell>
            <TableCell key={"faculty_name"} align={"center"}>
                {thesis.facultyName}
            </TableCell>
            <TableCell key={"speciality_name"}>
                {i18n.language === 'lv' ?
                    thesis.specialityNameLv :
                    thesis.specialityName
                }
            </TableCell>
            <TableCell key={"department_key"} width={80}>
                {i18n.language === 'lv' ?
                    DepartmentEnumLv.get(thesis.department) :
                    DepartmentEnum.get(thesis.department)
                }
            </TableCell>
            <TableCell key={"student_name"}>
                {thesis.studentFirstName + " " + thesis.studentLastName}
            </TableCell>
            <Tooltip key={"tool_tip_student_name"} title={
                <Typography fontSize={18}>
                    {i18n.language === 'lv' ?
                        thesis.thesisNameLv :
                        thesis.thesisNameEn
                    }
                </Typography>}>
                <TableCell key={"thesis_name"}>
                    {i18n.language === 'lv' ?
                        thesis.thesisNameLv.slice(0, 49).concat("...") :
                        thesis.thesisNameEn.slice(0, 49).concat("...")}
                </TableCell>
            </Tooltip>
            <TableCell key={"supervisor_name"}>
                {thesis.supervisorFullName}
            </TableCell>
            <TableCell align={!thesis.reviewerName ? "center" : "left"} key={"reviewer_name"}>
                {thesis.reviewerName ? thesis.reviewerName : "---"}
            </TableCell>
            <TableCell key={"graduation_year"}>
                {thesis.graduatedYear}
            </TableCell>
            <TableCell align={"center"} key={"semestr"}>
                {thesis.semester}
            </TableCell>
            <TableCell style={{width: 90}} key={"uploaded"}>
                {thesis.uploaded}
            </TableCell>
            <TableCell>
                <IconButton onClick={() => props.onInfoClick(thesis.id)}>
                    <InfoIcon/>
                </IconButton>
            </TableCell>
        </TableRow>
    )
}

export default ThesesRow;

const useStyles = makeStyles({
    header: {backgroundColor: "#234771"},
    headerCell: {color: 'white', fontSize: 16, fontWeight: 'bold'},
    table: {
        minWidth: 1200,
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#dddd",
    },
    row: {},
});
