import axios from "axios";
import IThesisFilesState from "../dto/IThesisFilesState";
import FileSaver, {saveAs} from "file-saver";
import IThesis from "../dto/search/IThesis";


const thesisAxios = axios.create({baseURL: 'https://backends-api.tsi.lv/gradeworks'})

const fetchThesisFilesState = async (studentId: number): Promise<IThesisFilesState> => {
    const response = await thesisAxios.get(`/thesis/${studentId}/files`)
    return await response.data
}

const uploadSupMaterialFile = async (thesisId: number, thesisFile: File): Promise<IThesisFilesState> => {
    const formData = new FormData();
    formData.append("file", thesisFile);
    const response = await axios.post(
        `https://backends-api.tsi.lv/gradeworks/thesis/${thesisId}/files/additional`, formData, {
            headers: {'Content-Type': 'multipart/form-data'}
        })

    return await response.data
}

const uploadThesisFile = async (thesisId: number, thesisFile: File): Promise<IThesisFilesState> => {
    const formData = new FormData();
    formData.append("file", thesisFile);
    const response = await axios.post(`https://backends-api.tsi.lv/gradeworks/thesis/${thesisId}/files/main`, formData,
        {headers: {'Content-Type': 'multipart/form-data'}})

    return await response.data
}

const fetchFinalDocumentById = async (id: number): Promise<void> => {
    window.open(`https://backends-api.tsi.lv/gradeworks/thesis/${id}/files/final`, "final")
}

const fetchFinalDocument = async (thesis: IThesis): Promise<void> => {
    FileSaver.saveAs(`https://backends-api.tsi.lv/gradeworks/thesis/${thesis.id}/files/final`,
        thesis.stNumber + " final " + thesis.studentFirstName + " " + thesis.studentLastName
    );
}

const fetchOriginalDocument = async (thesis: IThesis): Promise<void> => {
    FileSaver.saveAs(`https://backends-api.tsi.lv/gradeworks/thesis/${thesis.id}/files/original`,
        thesis.stNumber + " original " + thesis.studentFirstName + " " + thesis.studentLastName
    );
}

const getLinkToFinalWork = (thesis: IThesis): string => {
    return `https://backends-api.tsi.lv/gradeworks/thesis/${thesis.id}/files/original`
}

export {
    fetchFinalDocumentById,
    fetchOriginalDocument,
    fetchFinalDocument,
    fetchThesisFilesState,
    uploadSupMaterialFile,
    uploadThesisFile,
    getLinkToFinalWork
}
